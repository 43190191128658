
.stars i.material-icons{
	font-size: $font-size-lg;
}
.composition{
	background: url("../images/bg-composition.png") repeat;
}
.img-fluid{
	height: auto;
	width: 100%;
}

.page-end::before {
  content: "▼ ▲ ▼";
  display: block;
  width: 100%;
  text-align: center;
  margin: 25px;
}

.dropdown-toggle{
	&:after{
	  display: none;
	}
}
.dropdown.nav-item{
	@include media-breakpoint-up(md){
		.nav-link.show{
			color: $p2pu-blue;
		}
		.dropdown-menu {
			border-radius: 0;
			border: 1px solid $light;
			background-color: $cream;
			min-width: unset;
			&.show{
				li{
					>.dropdown-item {
						border-top: 1px solid $light;
					}
					&:first-of-type{
						>.dropdown-item {
							border-top: 4px solid $p2pu-blue;
						}
					}
				}
			}
		}
	}
}

#nav-account {
	.dropdown-menu {
		border-radius: 0;
		border: 1px solid $light;
		min-width: unset;
		&.show{
			li{
				>.dropdown-item {
					border-top: 1px solid $light;
				}
				&:first-of-type{
					>.dropdown-item {
						border-top: 4px solid $p2pu-orange;
					}
				}
			}
		}
		@include media-breakpoint-down(md){
      position: inherit;
      border: none;
      box-shadow: none !important;
      li > .dropdown-item {
        border: none !important;
      }
      .p2pu-btn {
        padding-left: 0px;
      }
		}
	}
}

nav.main-nav{
	@include media-breakpoint-down(md){
		.dropdown-menu {
			display: block;
			&.shadow{
				box-shadow: none !important;
			}
		}
	}
}

.dropdown-menu {
	padding: $dropdown-padding-y $dropdown-padding-x;
	padding-top: 0;
	background-color: transparent;
	border: 0;
	@include media-breakpoint-up(md){
		display: none; // none by default, but block on "open" of the menu
		background-color: $dropdown-bg;
		border: $dropdown-border-width solid $dropdown-border-color;
		padding-top: $dropdown-padding-y;
	}

	.dropdown-item {
		@include font-size($nav-link-font-size);
		&:hover,
		&:focus {
			color: $dropdown-link-hover-color;
			text-decoration: if($link-hover-decoration == underline, none, null);
		    @include gradient-bg($dropdown-link-hover-bg);
		}
		
		&.active,
		&:active {
		  	color: $dropdown-link-active-color;
		  	text-decoration: none;
		  	@include gradient-bg($dropdown-link-active-bg);
		}
		
		&.disabled,
		&:disabled {
		 	color: $dropdown-link-disabled-color;
		 	pointer-events: none;
		  	background-color: transparent;
		  	// Remove CSS gradients if they're enabled
		  	background-image: if($enable-gradients, none, null);
		}
	}
}  

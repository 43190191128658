@import 'bootstrap/scss/mixins/_breakpoints';

.card.topic{
	.card-header{
		margin-bottom: 0;
		background-color: transparent;
		border-bottom: 0;
		padding: .5rem 1rem;
		color: $gray;
		.card-title {
			padding:0;
			margin:0;
			font-size: $font-size-xl;
			line-height: $line-height-base;
			font-weight: $font-weight-semibold;
		}
	}
}

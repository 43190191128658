// @import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@700&family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap');

$font-path: "";

@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/Merriweather-Bold.woff2") format('woff2');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  src: local("Open Sans"), url("../fonts/OpenSans-Regular-webfont.woff") format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans Semibold"), url("../fonts/OpenSans-Semibold-webfont.woff") format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  font-style: bold;
  font-weight: 700;
  src: local("Open Sans Bold"), url("../fonts/OpenSans-Bold-webfont.woff") format('woff');
  font-display: swap;
}


@import 'bootstrap/scss/mixins/_breakpoints';

.learning-circle-card-link {
  text-decoration: none;
  width: 100%;
}

.learning-circle-card-link:hover {
  text-decoration: none;
  transform: translate(0px, -4px);
  transition: all 0.3s ease;
}

.card.learning-circle {
  display: block;
}

.card.learning-circle {
  box-shadow: none;
  font-size: inherit;
  display: block;
  flex-direction: inherit;
  flex-grow: inherit;
  margin-bottom: 0;
  margin-top: 0;
  border-radius: 0;
  border: none;
  padding: 0;
  color: inherit;
  transition: none;
  &:hover{
    box-shadow: $box-shadow;
  }
  .status-tag {
    position: absolute;
    right:0;
    top:0;
    background: white;
    padding: 0;
    border: 0;
  }
  .card-header{
    .card-title{
      padding-left: 0;
      padding-right: 0;
    }
  }
  .card-body{
    padding: 0;
    color: inherit;
    padding: $padding-md;
    padding-top: 0;
    color: $gray;
  }
  border-top: 6px solid $gray;
  transition: all 0.3s ease;
  box-shadow: $box-shadow;
  margin-bottom: $grid-gutter-width;
  margin-top: $grid-gutter-width;
  .status-tag {
    position: absolute;
    right: 0px;
    top: 0px;
    background: $gray;
    padding: 0px 10px 4px 10px;
    letter-spacing: .5px;
    border: 0;
    font-size: $xsmall-font-size;
    font-size: $font-size-xxsm;
    text-transform: uppercase;
    font-weight: bold;
    color: $white;
  }
  &.p2pu-orange, &.in-progress{
    border-top: 6px solid $p2pu-orange;
    .status-tag {
      background: $p2pu-orange;
    }
  }
  &.p2pu-blue, &.starting-soon{
    border-top: 6px solid $p2pu-blue;
    .status-tag {
      background: $p2pu-blue;
    }
  }
  &.p2pu-green, &.closed{
    border-top: 6px solid $p2pu-green;
    .status-tag {
      background: $p2pu-green;
    }
    .card-footer {
      button{
        visibility: hidden;
      }
    }
  }
  &.p2pu-yellow, &.signup-closed{
    border-top: 6px solid $p2pu-yellow;
    .status-tag {
      background: $p2pu-yellow;
    }
    .card-footer {
      button{
        visibility: hidden;
      }
    }
  }
  .card-footer {
    padding: $padding-md;
    padding-top: 0;
    background-color: transparent;
    border-top: none;
    button{
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

@import 'bootstrap/scss/mixins/_breakpoints';

.card-testimonial{
  // TODO remove - not sure if this was ever used, seems to have been overridden before
	//&:not(.basic){
	//	border-top-width: 10px;
	//}
	.quote, .blockquote {
		position: relative;
		font-size: $font-size-base;
		q{
			&:before, &:after{
				font-family: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Geneva, Verdana, sans-serif;
			}
		}
		footer{
			padding-top: $padding-sm;
		}
	}
	.attribution {
		.image {
			img {
				border-radius: 50%;
				max-height: 100px;
				max-width: 100px;
				height: auto;
				width: 100%;
			}
		}
		span{
			display: block;
		}
	}
}

.slick-slide{
	.card-testimonial{
		margin-top: 0;
		margin-bottom: 0;
		margin: $padding-sm;
		.card-body{
			padding-top: $padding-md;
		}
	}
}	

@import 'bootstrap/scss/mixins/_breakpoints';

.card.card-case-study{
	.card-image{
		height: 0;
		position: relative;
		padding-top: 60%;
		@include media-breakpoint-up(sm) {
			padding-top: 40%;
		}
		@include media-breakpoint-up(md) {
			padding-top: 60%;
		}
		@include media-breakpoint-up(lg) {
			padding-top: 40%;
		}
		@include media-breakpoint-up(xl) {
			padding-top: 60%;
		}
		img{
			position: absolute;
			top: 0;
		}
	}
	.card-header {
		padding: $padding-md;
		.eyebrow{
			color: $p2pu-blue;
			font-size: $font-size-sm;
			text-transform: uppercase;
			font-weight: $font-weight-normal;
			letter-spacing: .03em;
		}
	}
}
.slick-slide{
	.card.card-case-study{
		margin: $padding-sm;
	}
}	

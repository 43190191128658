@import 'bootstrap/scss/mixins/_breakpoints';

.card.teams{
	.card-image{
		height: 0;
		padding-top: 100%;
		position: relative;
		img{
			position: absolute;
			top: 0;
		}
	}
	.card-header, .card-body, .card-footer {
		text-align: center;
	}
	.card-body>span{
		font-size: $font-size-lg;
	}
}

body {
  &.freeze {
    overflow: hidden;
  }
}
header.fixed-top{
  img.logo {
    height: 38px;
    width: auto;
    @include media-breakpoint-up(md) {
      height: 44px;
    }
  }
  nav{
    .p2pu-btn{
      @media (max-width: map-get($grid-breakpoints, "md")) {
        border: 0 !important;
        padding-left: 0px;
        padding-right: 0px;
        margin-top: 0.5rem;
        &:before{
          content: none !important;
        }
        &:focus{
          box-shadow: none !important;
        }
      }
    }
  }
  
  .hamburger{
    margin: 0;
    padding: 0;
    cursor: pointer;
    transition-timing-function: linear;
    transition-duration: .15s;
    transition-property: opacity,filter;
    text-transform: none;
    color: inherit;
    border: 0;
    background-color: transparent;
    &:focus {
      box-shadow: none !important;
    }
    .hamburger-box {
      position: relative;
      display: inline-block;
      width: 47px;
      height: 30px;
      .hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
        position: absolute;
        width: 47px;
        height: 2px;
        transition-timing-function: ease;
        transition-duration: .15s;
        transition-property: transform;
        border-radius: 4px;
        background-color: $gray;
        display: block;
      }
      .hamburger-inner:before{
        top: 14px;
        transition-timing-function: ease;
        transition-duration: .15s;
        transition-property: transform,opacity;
        content: "";
      }
      .hamburger-inner:after{
        bottom: -14px;
        top: 28px;
        content: "";
      }
    }
    &[aria-expanded="true"]{

      .hamburger-inner{
        transform: translate3d(0,14px,0) rotate(45deg);
      }
  
      .hamburger-inner:after {
          transform: translate3d(0,-28px,0) rotate(-90deg);
      }
      .hamburger-inner:before {
        transform: rotate(-45deg) translate3d(-5.71429px,-6px,0);
        opacity: 0;
      }
      .hamburger-inner:after {
        transform: translate3d(0,-28px,0) rotate(-90deg);
      }

    }
  }
  
  .navbar-nav{
    .dropdown{
      .dropdown-toggle{
        &:after{
          display: none;
        }
      }
      .dropdown-menu{
        
      }
    }
    .nav-item{
      .nav-link{
        @media (max-width: map-get($grid-breakpoints, "md")) {
          padding-bottom: 0;
        }
      }
    }
  }
  
  .header-top{
    .logged-out{
      @media (max-width: map-get($grid-breakpoints, "md")) {
        width: 100vw;
        position: relative;
        margin-left: calc(-30px + -8.5%);
        padding-left: calc(1rem + 30px + 8.5%);
        border-top: 1px solid #E2E1DD !important;
        border-bottom: 1px solid #E2E1DD !important;
        .p2pu-btn{
          padding-left: 0 !important;
          padding-right: 0 !important;
          background-color: transparent !important;
          font-size: .95rem !important;
        }
      }
      @media (max-width: map-get($grid-breakpoints, "sm")) {
        margin-left: -30px;
        padding-left: calc(1rem + 30px);
      }
    }
  }
  
  .header-bottom{
   .nav-item:last-of-type{
     .nav-link{
       @include media-breakpoint-up(lg) {
         padding-right: 0;
       }
     }
   }
  }
}

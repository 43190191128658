@import 'bootstrap/scss/mixins/_breakpoints';

.card.staff{
	box-shadow: none;
	background: transparent;
	border: 0;
	.card-image{
		height: 0;
		padding-top: 100%;
		position: relative;
		img{
			position: absolute;
			top: 0;
		}
	}
	.card-header, .card-body, .card-footer {
		padding-left: 0;
		padding-right: 0;
		padding-bottom: $padding-sm;
	}
	.card-header{
		h3+p{
			font-size: $font-size-lg;
			font-weight: $font-weight-bold;
			color: $text-muted;
		}
	}
}

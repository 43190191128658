@import 'bootstrap/scss/mixins/_breakpoints';

@import "p2pu-theme/src/scss/cards/staff-card";
@import "p2pu-theme/src/scss/cards/teams-card";
@import "p2pu-theme/src/scss/cards/board-card";
@import "layout-elements/dot-block";

#about{
  .header-wrap{
    background-color: $p2pu-light-blue;
    overflow: hidden;
    .subtitle{
      font-size: $font-size-xl;
    }
  }
  section{
    >.container{
      border-top: 12px solid $gray-snow;
    }
  }
  .about-us{
    font-size: $font-size-lg;
    >.container{
      border-top: 0;
      @include media-breakpoint-up(md){
        padding-top: $padding-lg * 2;
      }
    }
  }
  .values{
    h3{
      font-size: 1.25rem;
    }

    img {
      clip-path: circle(50% at center);
    }
  }
  .funders .funder img {
    max-width: 200px;
    filter: grayscale(1);
  }

}

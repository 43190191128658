@import 'bootstrap/scss/mixins/_breakpoints';

#teams{

  @import "layout-elements/slider";

  .header-wrap{
    background-color: $p2pu-light-blue;
    overflow: hidden;
  } 
  .card.plain{
    .card-image{
      height: 250px;
    }
    .card-header {
      margin: 0;
      padding: 1em 0;
    }
    .card-title {
      margin: 0;
    }
  } 
  
  // fix in page anchor
  #get-started-now::before {
    content: '';
    display: block;
    height: 160px;
    margin-top: -160px;
    visibility: hidden;
  }

  #current-teams::before {
    content: '';
    display: block;
    height: 160px;
    margin-top: -160px;
    visibility: hidden;
  }

}

@import 'bootstrap/scss/mixins/_breakpoints';

@import "p2pu-theme/src/scss/cards/testimonials-card";
@import "p2pu-theme/src/scss/cards/case-study-card";
@import "layout-elements/dot-block";

#home{

  @import "layout-elements/slider";

  .landing h1 {
    //color: $cream;
  }
	h2{
		font-family: $font-family-serif;
	}
	section.landing {
		p.large {
			max-width:800px;
			margin:0 auto;
		}
		> .container > .row {		
			margin-bottom: 8em;
			@include media-breakpoint-up(sm) {
					margin-bottom: 10em;	
				}	
			@include media-breakpoint-up(lg) {
				margin-bottom: 18vw;	
			}	
		}
	}
	
	section.learning-circles {
		.container{
			.dot-quad{
				p{
					font-size: $font-size-lg;
				}
			}
		}

    img {
      clip-path: circle(50% at center);
    }

	}
	
	section#map-section {
		position: relative;
		height: 45vw;
		min-height: 500px;
		max-height: 750px;
		border: 5px solid $p2pu-blue;
		border-width: 5px 0;
		background: url(../images/map-bkgd.webp) no-repeat;
		background-size: cover;
		
		#global-map{
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 0;
		}
		
		.container-fluid {
			position: relative;
			@include media-breakpoint-down(sm) {
	            padding-top: 1em !important;			
				.col-md-6 {
					position: absolute;
					bottom: 10px;
					left: 3%;
					width: 94%;
					button {
						margin-top: 0 !important;
						margin-bottom: 0 !important;
					}
				}
			}
		}
			
		.marker {
		  background-image: url('../images/marker.png');
		  background-size: cover;
		  width: 24px;
		  height: 32px;
		}
		
		.mapboxgl-popup-content  {
			border:3px solid #05C7B4;
			border-radius: 80px;
			padding: 5px 18px;
			font-size:14px;
			color:#646462;
			font-family: 'Open Sans', sans-serif;
			box-shadow: 0 2px 4px rgba(0 ,0, 0, .2);
			margin:0;
		}
		
		.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
		  border-bottom-color: #05C7B4;	
		}
		
		.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
		  border-top-color:#05C7B4;
		}
		.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
			border-right-color:#05C7B4;
		}
		.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
			border-left-color:#05C7B4;
		}	
			
	}
	
	section.community{
		@include media-breakpoint-up(lg) {
			.col-lg-5{
				position: absolute;
				right: 0;
			}
		}
	}
	
	section.team {
		@include media-breakpoint-up(lg) {
			.col-lg-5{
				position: absolute;
				left: 0;
			}
		}
	}
	
	section.about{
		.slick-slide {
			cursor: pointer;
			q{
				&:before, &:after{
					font-family: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Geneva, Verdana, sans-serif;
					font-size: 4.5rem;
					line-height: 0;
					height: 0;
					box-sizing: content-box;
					max-height: 0;
					display: inline-block;
					width: 0;
					position: absolute;
				}
				&:before{
					transform: translate(-35px, 25px);
				}
				&:after{
					transform: translate(5px, 35px);
				}
			}
		}

    .card.cover-link > a {
      z-index: 999;
    }
	
	}

}

@import 'bootstrap/scss/mixins/_breakpoints';

@import "layout-elements/contact-form.scss";

body#help {
  .card img {
    object-fit: contain;
  }

  .card .card-image {
    padding-top: 1rem;
  }

}

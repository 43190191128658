// Base class
//
// Kickstart any navigation component with a set of style resets. Works with
// `<nav>`s, `<ul>`s or `<ol>`s.



//
// Tabs
//

.nav-tabs {
  border-bottom: $nav-tabs-border-width solid $gray;

  .nav-link {
    font-size: 1rem;
    text-transform: uppercase;
  	margin-bottom: 0;
  	background: none;
  	border: none;
    border-bottom: 7px solid transparent;
    display: inline-block;
  	@include border-top-radius($border-radius-sm);
    &:first-of-type{
      margin-left: 0;
    }
  
  	&:hover,
  	&:focus {
  	  border-color: transparent;
  	  // Prevents active .nav-link tab overlapping focus outline of previous/next .nav-link
  	  isolation: isolate;
  	}
  
  	&.disabled {
  	  color: $nav-link-disabled-color;
  	  background-color: transparent;
  	  border-color: transparent;
  	}
  }

  .nav-link.active,
  .nav-item.show .nav-link {
	color: $nav-tabs-link-active-color;
	background-color: $nav-tabs-link-active-bg;
	border-color: $gray;
  }

  .dropdown-menu {
	// Make dropdown border overlap tab border
	margin-top: -$nav-tabs-border-width;
	// Remove the top rounded corners here since there is a hard edge above the menu
	@include border-top-radius(0);
  }
}


//
// Pills
//

.nav-pills {
  .nav-link {
	background: none;
	border: 0;
	@include border-radius($nav-pills-border-radius);
  }

  .nav-link.active,
  .show > .nav-link {
	color: $nav-pills-link-active-color;
	@include gradient-bg($nav-pills-link-active-bg);
  }
}


//
// Justified variants
//

.nav-fill {
  > .nav-link,
  .nav-item {
	flex: 1 1 auto;
	text-align: center;
  }
}

.nav-justified {
  > .nav-link,
  .nav-item {
	flex-basis: 0;
	flex-grow: 1;
	text-align: center;
  }
}

.nav-fill,
.nav-justified {
  .nav-item .nav-link {
	width: 100%; // Make sure button will grow
  }
}


// Tabbable tabs
//
// Hide tabbable panes to start, show them when `.active`

.tab-content {
  > .tab-pane {
	display: none;
  }
  > .active {
	display: block;
  }
}

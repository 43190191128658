@import 'bootstrap/scss/mixins/_breakpoints';

.row {
	&[class*=" dot-"] {	  
		>div{
			.icon{
				position: relative;
			//&:before{
			//	content: "";
			//	background-color: $p2pu-blue;
			//	position: absolute;
			//	width: 70%;	
			//	height: 70%;
			//	top: 15%;
			//	left: 15%;
			//	border-radius: 50%;
			//}
				img{
					position: relative;
					max-height: 100%;
					max-width: 100%;
				}
			}
		//&:nth-of-type(2){
		//	:before{
		//		background-color: $p2pu-green;
		//	}
		//}
		//&:nth-of-type(3){
		//	:before{
		//		background-color: $p2pu-yellow;
		//	}
		//}
		//&:nth-of-type(4){
		//	:before{
		//		background-color: $p2pu-orange;
		//	}
		//}
		}
	}
	&.dot-sextet{
		>div{
			display: flex;
			align-items: center;
			.icon{
				width: 35%;
			}
			&:nth-of-type(5){
				:before{
					background-color: $p2pu-blue;
				}
			}
			&:nth-of-type(6){
				:before{
					background-color: $p2pu-green;
				}
			}
			h3{
				flex-grow: 1;
			}
		}
	}
}


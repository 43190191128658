@import 'bootstrap/scss/mixins/_breakpoints';

@import "p2pu-theme/src/scss/cards/learning-circles-card";
@import "layout-elements/dot-block";

#learning-circles {
  .dot-quad{
    h4{
      margin-bottom: 0;
    }
  }
}

html {
  height: 100%
}

body {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  main {
    flex: 1 0 auto;
    padding-top: $navbar-height-sm;
    background-color: $cream;
    @include media-breakpoint-up(md) {
      padding-top: $navbar-height;
    }
    section{
      >.container{
        padding-top: $padding-lg;
        padding-bottom: $padding-lg;
        @include media-breakpoint-up(lg) {
          padding-bottom: $padding-lg * 2;
        }
      }
    }
  }
  .page-header {
    margin-top: $spacer * 2;
    margin-bottom: $spacer * 3;
    @include media-breakpoint-up(md) {
      margin-top: $spacer * 4;
    }
    .subtitle{
      font-size: $font-size-lg;
    }
  }
  
  
}


@import "card-base";

.card.generic{

	.card-header{
		margin-bottom: 0;
		background-color: transparent;
		border-bottom: 0;
		padding: 1rem;
		color: $gray;
		.card-title {
			padding:0;
			margin:0;
			line-height: $line-height-base;
			font-weight: $font-weight-bold;
			
		}
		h4.card-title{
			font-size: $font-size-lg;
		}
		
	}
}

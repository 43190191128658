.form-control{
	&::placeholder, &select:invalid {
		font-style: italic;
		font-size: $font-size-base;
		color: $text-muted;
	}
	&.dark{
		color: $white;
		background-color: $gray;
		&::placeholder, &select:invalid {
			color: rgba(255,255,255,0.5);
		}
	}
}

select:invalid.form-control {
	font-style: italic;
	font-size: $font-size-base;
	color: $text-muted;
	line-height: 1.78rem;
}

.form-select{
	padding: $input-padding-y $input-padding-x;
}


form{
	&.filter{
		.input-group-md{
			@include media-breakpoint-up(md) {
				position: relative;
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				align-items: center;
				.form-control, .form-select {
					position: relative;
					flex: 1 1 auto;
					width: 1%;
					min-width: 0;
				}
			}
			input, select{
				border-radius: $border-radius !important;
			}
		}
	}
	&.search{
		input{
			padding-right: $input-padding-y;
		}
		.input-group-text{
			.material-icons{
				font-weight: bold;
				//font-size: 2rem; // TODO: I break consistent heights for input fields! Muhahaha!
				padding-left: $input-padding-y;
			}
		}
	}
	input[type="search" i]::-webkit-search-cancel-button {
		margin-right: 0;
	}
}

// topic badges? - what is this? Why is it part of forms?
.badge{
	padding: 5px 8px 6px 8px;
	&.topic{
		background-color: $gray;
		&-selected{
			background-color: $p2pu-yellow;
			color: $gray-dark;
			padding: 5px 8px 6px 5px;
			.dismiss{
				font-size: $font-size-xxsm;
				font-weight: $font-weight-bold;
				margin-right: 3px;
				background: white;
				border-radius: 50%;
				padding: 1.2px .8px .8px 1.2px;
				vertical-align: top;
			}
		}
	}
}


@import 'bootstrap/scss/mixins/_breakpoints';

@import "p2pu-theme/src/scss/cards/course-card";
@import "p2pu-theme/src/scss/cards/topic-card";
@import "layout-elements/sidebar";

#courses {
  .filter-card{
    background-color: white !important;
  }
  .filter-card.show{
    background-color: $cream;
  }

}


#courses-page {
.sidebar{
	.navbar-toggler{
		.material-icons{
			display: flex;
			&:after{
				content: "FILTERS";
				font-size: $font-size-sm;
				font-weight: $font-weight-bold;
				font-family: $font-family-sans-serif;
				display: inline-flex;
				align-self: center;
				padding-left: .5rem;
				border-bottom: 1px solid $gray-light;
			}
		}
	}
	h3.offcanvas-title{
		border-bottom: 1px solid $gray-light;
	}
	.filter-fields{
		max-height: calc(100vh - 130px);
		overflow: scroll;
		>.col-12{
			padding-top: $padding-sm * 2;
			padding-bottom: $padding-sm * 2;
			border-top: 1px solid $gray-light;
			&:first-of-type{
				border-top: none;
				padding-top: 0;
			}
			form:not(:first-of-type){
				padding-top: $padding-sm * 2;
			}
			
		}
	}
}
}

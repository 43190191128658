@import "variables";

.accordion {
  padding-bottom: $padding-sm;

  h2 {
    padding-bottom: $padding-xs;
  }

  .accordion-item {
    border: none;
  }
}
@import 'bootstrap/scss/mixins/_breakpoints';

.sidebar.sticky-top{
	top: $navbar-height-sm;
	@include media-breakpoint-up(lg) {
	  top: $navbar-height;
	  padding-top: $padding-sm * 2;
	  border-right: 1px solid $gray-light;
	  border-bottom: 1px solid $gray-light;
	  margin-bottom: 35px;
	}
	.navbar-toggler{
		@include media-breakpoint-down(lg) {
			width: 100%;
			display: block;
			align-items: start;
			text-align: left;
			background-color: $cream;
			color: $gray;
			border-radius: 0;
		}
	}
	.wrap{
		@include media-breakpoint-down(lg) {
			top: $navbar-height;
		}
		@include media-breakpoint-down(md) {
			top: $navbar-height-sm;
		}
		.offcanvas-header{
			background-color: $cream-dark;
		}
	}
  }
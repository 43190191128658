body {
  font-family: $font-family-sans-serif;
  font-size: 16px;

  a {
    color: $link-color;

    &.light {
      color: $white;
    }

    &.dark {
      color: $gray-dark;
    }

    &.underline {
      text-decoration: underline;
    }

    &:hover, &:focus, &:active {
      opacity: 0.9;
    }

    &.undecorated {
      color: inherit;

      &:hover, &:focus, &:active {
        opacity: 1;
        text-decoration: none;
      }
    }
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: $headings-margin-top;
  }
  
  h1{
    font-family: $font-family-serif;
  }
  h3{
    font-size: $h4-font-size;
  }
  h4,h5,h6{
    font-size: $font-size-base;
  }

  .text-xxsm {
    font-size: $font-size-xxsm; // 12px
  }
  .text-xsm {
    font-size: $font-size-xsm; // 13px
  }
  
  .text-sm{
    font-size: $font-size-sm; // 14px
  }

  .text-lg {
    font-size: $font-size-base; // 16px
    @include media-breakpoint-up(sm) {
      font-size: $font-size-lg; // 18px
    }
  }
  
  .text-xl {
    font-size: $font-size-lg; // 18px
    @include media-breakpoint-up(sm) {
      font-size: $font-size-xl; // 24px
    }
  }


  .fw-semibold{
    font-weight: $font-weight-semibold;
  }

  .text-uppercase{
    letter-spacing: .03em;
  }

}
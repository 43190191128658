@import 'bootstrap/scss/mixins/_breakpoints';

.grid-item{
	align-items: stretch;
	display: flex;
}

.card{
	transition: all 0.3s ease;
	box-shadow: $box-shadow;
	margin-bottom: $grid-gutter-width;
	margin-top: $grid-gutter-width;
	width: 100%;
	.status-tag {
		position: absolute;
		right: 0px;
		top: 0px;
		background: $gray;
		padding: 0px 10px 4px 10px;
		letter-spacing: .5px;
		border: 0;
		font-size: $xsmall-font-size;
		font-size: $font-size-xxsm;
		text-transform: uppercase;
		font-weight: bold;
		color: $white;
	}
	&.p2pu-orange, &.in-progress, &.learner{
		border-top: 6px solid $p2pu-orange;
		.status-tag {
			background: $p2pu-orange;
		}
		q{
			&:before, &:after{
				color: $p2pu-orange;
			}
		}
	}
	&.p2pu-blue, &.starting-soon, &.card-case-study{
		border-top: 6px solid $p2pu-blue;
		.status-tag {
			background: $p2pu-blue;
		}
		q{
			&:before, &:after{
				color: $p2pu-blue;
			}
		}
	}
	&.p2pu-green, &.closed, &.facilitator{
		border-top: 6px solid $p2pu-green;
		.status-tag {
			background: $p2pu-green;
		}
		.card-footer {
			button{
				visibility: hidden;
			}
		}
		q{
			&:before, &:after{
				color: $p2pu-green;
			}
		}
	}
	&.p2pu-yellow, &.signup-closed, &.network{
		border-top: 6px solid $p2pu-yellow;
		.status-tag {
			background: $p2pu-yellow;
		}
		.card-footer {
			button{
				visibility: hidden;
			}
		}
		q{
			&:before, &:after{
				color: $p2pu-yellow;
			}
		}
	}
	.card-image{
		width: 100%;
		height: 145px;
		overflow: hidden;
		img{
			object-fit: cover;
			height: 100%;	
			width: 100%;
		}
	}
	.card-header{
		margin-bottom: 0;
		background-color: transparent;
		border-bottom: 0;
		padding: $padding-md;
		padding-bottom: 0;
		color: $gray;
		.eyebrow{
			font-weight: bold;
			font-size: $font-size-xsm;
			margin-bottom: $spacer * .3;
		}
		.lowbrow{
			text-transform: uppercase;
		}
		.card-title {
			padding-left: 0;
			padding-right: 0;
			margin-bottom: $spacer;
			font-family: $font-family-sans-serif;
		}
		
	}
	.card-body{
		padding: $padding-md;
		padding-top: 0;
		color: $gray;
		>span{
			display: block;
			font-size: $font-size-xsm;
			margin-bottom: $spacer * .5;
			i.material-icons{
				font-size: $font-size-lg;
				vertical-align: middle;
				margin-right: $spacer * .3;
			}
		}
	}
	.card-footer {
		padding: $padding-md;
		padding-top: 0;
		background-color: transparent;
		border-top: none;
		button{
			margin-top: 0;
			margin-bottom: 0;
		}
		.text{
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
	}
	table{
		font-size: $font-size-xsm;
		margin-top: $padding-md;
		tbody{
			tr{
				&:first-of-type{
					border-top-width: 1px;
				}
				&:last-of-type{
					border-bottom: transparent;
				}
				a:not(.btn){
					color: inherit;
					text-decoration: underline;
				}
				> * {
					padding: .15rem .5rem;
				}
				th{
					text-align: right;
					font-weight: normal;
				}
			}
		}
	}
	&.horizontal{
		@include media-breakpoint-up(md) {
			.card-footer {
				padding: $padding-md;
				padding-left: 0;
			}
		}
	}
	&.cover-link{
		position: relative;
		> a {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
		}
	}
	&.plain{
		box-shadow: none;
		background: transparent;
		border: 0;
		.card-header, .card-body, .card-footer {
			padding-left: 0;
			padding-right: 0;
		}
	}
}
@import 'bootstrap/scss/mixins/_breakpoints';

.slick-slider.slick-dotted{
	.slick-dots li button:before{
		font-size: 3rem;
		font-family: $font-family-base;
	}
}

.slick-track
{
	display: flex !important;
}

.slick-slide
{
	height: inherit !important;
	align-items: stretch;
	display: flex !important;
}
.slick-arrow{
	position: absolute;
	right: 100%;
	width: fit-content;
	min-width: 2rem;
	cursor: pointer;
	top: 100%;
	&.prev-arrow{
		right: 100%;
		transform: translate(100%, 50%);
	}
	&.next-arrow{
		left: 100%;
		transform: translate(-100%, 50%);
	}
	@include media-breakpoint-up(md) {
		height: 100%;
		top: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		&.prev-arrow{
			right: 100%;
			transform: translate(0px, 0px);
		}
		&.next-arrow{
			left: 100%;
			transform: translate(0px, 0px);
		}
	}
}
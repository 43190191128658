@import 'bootstrap/scss/mixins/_breakpoints';

#donate{
  .container.bg-light-blue{
    max-width: 100%;
    margin-top: 0;
    margin-bottom: $spacer * 3;
    padding-top: 0;
    padding-bottom: $grid-gutter-width;
    @include media-breakpoint-up(lg) {
      margin-top: $spacer * 4;
      padding-top: $grid-gutter-width;
    }
    input.form-control.dark{
      padding: 0 .8rem;
    }
  }
}
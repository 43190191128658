.pos-absolute {
  position: absolute;
}

.pos-relative {
  position: relative;
}
.serif{
  font-family: $font-family-serif;
}
.sans-serif{
  font-family: $font-family-sans-serif;
}
.text {
  &-blue {
    color: $p2pu-blue;
  }

  &-green {
    color: $p2pu-green;
  }

  &-yellow {
    color: $p2pu-yellow;
  }

  &-orange {
    color: $p2pu-orange;
  }

  &-black {
    color: $black;
  }

  &-dark {
    color: $gray-dark;
  }

  &-gray {
    color: $gray;
  }
  
  &-light {
    color: $gray-light !important;
  }
  
  &-lighter {
    color: $gray-snow;
  }

  &-white {
    color: $white;
  }
  
  &-cream {
    color: $cream;
  }
  &-cream-dark {
    color: $cream-dark;
  }
  &-transparent{
    color: $transparent;
  }
  
  &-red-error {
    color: $red-error;
  }
  &-pink-error {
    color: $pink-error;
  }

}

.bg {
  &-blue {
    background-color: $p2pu-blue;
  }
  &-light-blue {
    background-color: $p2pu-light-blue;
  }
  
  &-green {
    background-color: $p2pu-green;
  }
  
  &-light-green {
    background-color: $p2pu-green;
  }

  &-yellow {
    background-color: $p2pu-yellow;
  }
  
  &-light-yellow {
    background-color: $p2pu-yellow;
  }

  &-orange {
    background-color: $p2pu-orange;
  }
  
  &-light-orange {
    background-color: $p2pu-orange;
  }

  &-dark {
    background-color: $gray-dark;
  }

  &-gray {
    background-color: $gray;
  }
  
  &-light {
    background-color: $gray-light !important;
  }

  &-lighter {
    background-color: $gray-snow;
  }
  
  &-cream {
    background-color: $cream;
  }
  &-cream-dark {
    background-color: $cream-dark;
  }
  
  &-transparent{
    background-color: $transparent;
  }
  &-red-error {
    background-color: $red-error;
  }
  &-pink-error {
    background-color: $pink-error;
  }
}

// stop column styles at breakpoint

.col{
  @include media-breakpoint-up(sm) {
    &-sm-0{
      display: inherit !important;
      flex-direction: column;
      width: auto;
    }
  }
  @include media-breakpoint-up(md) {
    &-md-0{
      display: inherit !important;
      flex-direction: column;
      width: auto;
    }
  }
  @include media-breakpoint-up(lg) {
    &-lg-0{
      display: inherit !important;
      flex-direction: column;
      width: auto;
    }
  }
  @include media-breakpoint-up(xl) {
    &-xl-0{
      display: inherit !important;
      flex-direction: column;
      width: auto;
    }
  }
  @include media-breakpoint-up(xxl) {
    &-xxl-0{
      display: inherit !important;
      flex-direction: column;
      width: auto;
    }
  }
}













footer#footer{
  .nav-heading{
    font-weight: bold;
    letter-spacing: .03em;
    text-transform: uppercase;
  }
  .nav{
    margin-bottom: auto;
    &:last-of-type{
      margin-bottom: 0;
    }
    .nav-item{
      margin-bottom: .25rem;
      a{
        color: $white;
      }
    }
  }
  .donate{
    span{
      padding-bottom: 2px;
    }
  }
  #mc_embed_signup_scroll{
    input.email{
      border-radius: 2em;
      border: 2px solid $cream;
      color: $cream;
      background: transparentize($cream, 0.75);
      &::placeholder{
        color: $cream;
      }
    }
  }
  .social-link{
    a{
      color: $cream;
      font-size: $h4-font-size;
      &:hover{
        color: transparentize($cream, 0.15);
      }
    }
  }
}

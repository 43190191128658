.card.board{
	box-shadow: none;
	background: transparent;
	border: 0;
	.card-image{
		height: 0;
		position: relative;
		width: 80%;
		padding-top: 80%;
		margin-left: 10%;
		img{
			position: absolute;
			top: 0;
		}
	}
	.card-header {
		padding-left: 0;
		padding-right: 0;
		text-align: center;
	}
}
